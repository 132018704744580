<template>
  <div class="temp">
    <div class="inahotei">
      <h1>早稲田祭2024企画「いなほ亭」</h1>
      <h2>入場無料！！</h2>
      <p>詳細は以下のパンフレットから↓</p>

      <div class="garally">
        <ul>
          <li>
            <div class="garally_contents">
              <img src="../assets/img28.jpg">
            </div>
          </li>
          <li>
            <div class="garally_contents">
              <img src="../assets/img29.jpg">
            </div>
          </li>
        </ul>
      </div>

      <h3>ご来場お待ちしています！！</h3>

      <!--過去の宣伝Youtube動画を入れ込み-->
      <div class="concept_video00">
        <p>Concept Video (2022)</p>
        <br>
        <iframe width="710px" height="399px"
          src="https://www.youtube.com/embed/tDAaLD27jSY?si=qJFMZzz91uHQ0XDe&autoplay=1&mute=1"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
    <br>

  </div>
</template>

<script>
export default {
  name: 'TempView',
};
</script>

<style scoped>
.temp {
  margin-top: 50px;
  padding-left: 20px;
  box-sizing: border-box;
}

.concept_video00 {
  padding-left: 50px;
}

.concept_video00 p {
  display: inline-block;
}

.concept_video00 iframe {
  margin-left: auto;
  margin-right: auto;
}

.map {
  text-align: center;
}

.garally {
  padding-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.garally ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  padding: 10px;
}

.garally ul li {
  width: auto;
  height: 500px;
  padding: 8px;
  display: block;
  margin: 15px;
}

.garally ul li img {
  width: auto;
  height: 500px;
}

.garally ul li p {
  width: auto;
  text-align: left;
  margin-top: 4px;
  position: relative;
}

.garally_contents {
  display: inline-block
}

@media screen and (max-width: 640px) {
  .concept_video00 {
    display: none;
  }
}</style>